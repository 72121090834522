import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack,  Box, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { useTheme } from '@emotion/react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

// ----------------------------------------------------------------------

export default function AddResultBanner() {

    const theme = useTheme();
    const animatedComponents = makeAnimated();
    const [restrict, setRestrict] = useState('')
    const [pdf, setPdf] = useState(false)

    const [loading, setLoading] = useState(false)
    const [focus, setFocused] = useState(false);
    
    const [val, setVal] = useState(false);
    const [list , setList] = useState([]);
    const [category , setCategory] = useState();

    const handleChangeCategory =  (newValue ) => {
      setCategory(newValue);
  }
    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        year: Yup.number().typeError('Year must be a number').integer('Year must be an integer').min(1900, 'Year must be 1900 or later') // Minimum year
                  .max(new Date().getFullYear(), 'Year cannot be in the future')
                 .required('Year is required'), 
        isActive: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        category: '',
        isActive: '',
    };
    const [file, setFile] = useState();

    const changeHandler =  (event) => {
        
        let size = event.target.files[0].size / (1024);
        // console.log("47" , event.target.files);
        if (size > 150) {
            setVal(true);
        } else {
            // console.log(event.target.files);
            setFile(event.target.files)
            var fileName = document.getElementById('file').value.toLowerCase();
            if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
            else { setRestrict('JPG Or PDF') }
        }

    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    // console.log("67" , file);
    

    const onSubmit = async (data) => {
        if (file) {
            const formData = new FormData();
            setLoading(true);
            Object.values(file).forEach(file => {
                formData.append("file", file);
            })
            formData.append('title', data.title);
            formData.append('year', data.year);

            formData.append('category', category?.value);
            formData.append('isActive', data.isActive);

            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
                
            };
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminSection/createResultBanner`, formData, config).then((response) => {
                if (response.data.status) {
                    toast.success(response.data.msg);
                    setLoading(false);
                    reset();
                    window.location.href = "/dashboard/resultBanner";
                } else {
                    toast.error(response.data.msg);
                    setLoading(false);
                }

            })
                .catch(e => {
                    //console.log(e);
                })
        }

    };

    useEffect(() => {
            const token = localStorage.getItem("token");
            async function fetchDetails() {
                const fullResponse = await fetch(
                    `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const responseJson = await fullResponse.json();
                setList(responseJson.data);
            }
            fetchDetails();
        

    }, []);

    return (<>
        <Stack spacing={3} px={5} >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title" label="Title" />
                    <RHFTextField name="year" label="Year" />

                    <Stack direction="row" spacing={3}>
                    <RHFTextField name="file"
                            onChange={changeHandler}
                            id="file"
                            label="File"
                            error={!file && val}
                            helperText='Image Only ( Maximum allowed size 100KB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            // inputProps={{ multiple: true }}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />
                    </Stack>
                        <ReactSelect
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={category}
                            onChange={handleChangeCategory}
                            placeholder='Please Select Category'
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base ,  zIndex: 9999 }) }}
                            isMulti={false}
                            options={list}
                        />
                 


                    <Stack direction="row" spacing={3}>


                        <RHFTextField name="isActive" label="Status" type='checkbox' select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>




                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        // onClick={() => {
                        //     setVal(true)
                        //     setMsg('Required')
                        // }}
                        >
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
