export const optionsForTeacher = [

    { label: 'General Permission', value: ['21', "23", "51"] }
    ,
    {
        label: "Users", value: '1', options: [
            {
                label: 'All Users', value: '1111'
            },
            {
                label: 'Specific Users', value: '111112'
            },
            {
                label: 'Course Purchases', value: '1112'
            },
            {
                label: 'Course Orders', value: '11121'
            },
            {
                label: 'Test Series Purchases', value: '1113'
            },
            {
                label: 'Purchase Report', value: '1114'
            },
            {
                label: 'User Report', value: '1115'
            },

            // /call To Action 
            {
                label: 'Call To Action', value: '1116'
            },

        ]
    },
    {
        label: "Batch", value: '2', options: [
            {
                label: 'All Batches', value: '21'
            },
            {
                label: 'All Batch Community', value: '2311'
            },
            {
                label: 'All Batch Doubt', value: '2312'
            },
            {
                label: 'Add Batch', value: '22'
            },
            {
                label: 'All Lecture', value: '23'
            },
            {
                label: 'Add Lecture', value: '24'
            },
            {
                label: 'Announcements', value: '25'
            },
            {
                label : 'Batch Feature' , value : '231'
            }
        ]
    }
    , {
        label: 'Test Series', value: '3'
        , options: [
            {
                label: 'All Test Series', value: '31'
            },
            {
                label: 'Add Test Series', value: '32'
            },
            {
                label: 'All Test', value: '33'
            },
            {
                label: 'Add Test', value: '34'
            },
        ]
    }
    , {
        label: 'Quiz', value: '4'
        , options: [
            {
                label: 'All Quiz', value: '41'
            },
            {
                label: 'Add Quiz', value: '42'
            },
            {
                label: 'All Question', value: '43'
            },
            {
                label: 'Add Question', value: '44'
            },
        ]
    }
    , {
        label: 'Coupon', value: '5'
        , options: [
            {
                label: 'All Coupon', value: '51'
            },
            {
                label: 'Add Coupon', value: '52'
            },

        ]
    }
    , {
        label: 'Assignment', value: '7'
        , options: [
            {
                label: 'All Assignment', value: '71'
            },
            {
                label: 'Add Assignment', value: '72'
            },

        ]
    },
    {
        label: 'Store', value: '11'
        , options: [
            {
                label: 'Store Category', value: '111'
            },
            {
                label: 'Store Banner', value: '112'
            },
            {
                label: 'Store Featured Video', value: '113'
            },
            {
                label: 'Store Alerts', value: '114'
            },
            {
                label: 'All Product', value: '115'
            },
            {
                label: 'Add Product', value: '116'
            },
            {
                label: 'Store Order Report', value: '117'
            },
            {
                label: 'Store Purchase Report', value: '118'
            },
            {
                label: 'Store Wishlist', value: '119'
            },
            {
                label: 'Store Cart', value: '120'
            },
            {
                label: 'Store User', value: '1201'
            },
            {
                label: 'All Trashed Product', value: '1202'
            },
            {
                label: 'Store Coupon', value: '1203'
            },
        ]
    },
    {
        label: 'CMS', value: '12'
        , options: [
            {
                label: 'All Blogs', value: '121'
            },
            {
                label: 'Add Blog', value: '122'
            },

        ]
    },
    {
        label: 'Community', value: '13'
        , options: [
            {
                label: 'All Community', value: '131'
            },
            // {
            //     label: 'Add Community', value: '132'
            // },

        ]
    }
    ,
    {
        label: 'Push Notification', value: '14'
        , options: [
            {
                label: 'All Notiification', value: '141'
            },
            {
                label: 'Add Notification', value: '142'
            },

        ]
    },
    {
        label: 'ScholarShip Test', value: '15'
        , options: [
            {
                label: 'All Scholarship Test', value: '151'
            },
            {
                label: 'Add ScholarShip Test', value: '152'
            },

        ]
    }
    
]

export const options = [
    {
        label : 'All Tabs' , value : '0' , options : [
            
                {
                    label: 'All Tabs( Admin feature)', value: 'all'
                }
            
        ]
    },

    {
        label: "Users", value: '1', options: [
            {
                label: 'All Users', value: '1111'
            },
            {
                label: 'Course Purchases', value: '1112'
            },
            {
                label: 'Course Orders', value: '11121'
            },
            {
                label: 'Test Series Purchases', value: '1113'
            },
            {
                label: 'Purchase Report', value: '1114'
            },
            {
                label: 'User Report', value: '1115'
            },
            {
                label: 'Call To Action', value: '1116'
            },
        ]
    },
    {
        label: "Batch", value: '2', options: [
            {
                label: 'All Batches', value: '21'
            },
            {
                label: 'All Batch Community', value: '2311'
            },
            {
                label: 'All Batch Doubt', value: '2312'
            },
            {
                label: 'Add Batch', value: '22'
            },
            {
                label: 'All Lecture', value: '23'
            },
            {
                label: 'Add Lecture', value: '24'
            },
            {
                label: 'Announcements', value: '25'
            },
            {
                label : 'Batch Feature' , value : '231'
            }
        ]
    }
    , {
        label: 'Test Series', value: '3'
        , options: [
            {
                label: 'All Test Series', value: '31'
            },
            {
                label: 'Add Test Series', value: '32'
            },
            {
                label: 'All Test', value: '33'
            },
            {
                label: 'Add Test', value: '34'
            },
        ]
    }
    , {
        label: 'Quiz', value: '4'
        , options: [
            {
                label: 'All Quiz', value: '41'
            },
            {
                label: 'Add Quiz', value: '42'
            },
            {
                label: 'All Question', value: '43'
            },
            {
                label: 'Add Question', value: '44'
            },
        ]
    }
    , {
        label: 'Coupon', value: '5'
        , options: [
            {
                label: 'All Coupon', value: '51'
            },
            {
                label: 'Add Coupon', value: '52'
            },

        ]
    }
    , {
        label: 'Resources', value: '6'
        , options: [
            {
                label: 'Recorded Video', value: '61'
            },
            {
                label: 'Add Recorded Video', value: '611'
            },
            {
                label: 'Pathyakram', value: '62'
            },
            {
                label: 'Add Pathyakram', value: '622'
            },
            {
                label: 'Notes', value: '63'
            },
            {
                label: 'Add Notes', value: '633'
            },
            {
                label: 'Previous Year Question Papers', value: '64'
            },
            {
                label: 'Add Previous Year Question Papers', value: '644'
            },
            {
                label: 'AIR', value: '65'
            },
            {
                label: 'Add AIR', value: '655'
            },
            {
                label: 'News Clip', value: '66'
            },
            {
                label: 'Add News Clip', value: '666'
            },
            {
                label: 'Teacher Generated Content Of Batch', value: '67'
            },
            {
                label: 'Add Teacher Generated Content Of Batch', value: '677'
            },
            {
                label: 'Alerts', value: '68'
            },
            {
                label: 'Add Alerts', value: '688'
            },

        ]
    }
    , {
        label: 'Assignment', value: '7'
        , options: [
            {
                label: 'All Assignment', value: '71'
            },
            {
                label: 'Add Assignment', value: '72'
            },

        ]
    }
    , {
        label: 'Quick Learning', value: '888'
        , options: [
            {
                label: 'Channel', value: '8881'
            },
            {
                label: 'Add Channel', value: '8882'
            },
            {
                label: 'Shorts', value: '8883'
            },
            {
                label: 'Add Short', value: '8884'
            },
            
        ]
    }
    , {
        label: 'Manage Staff', value: '8'
        , options: [
            {
                label: 'All Staff', value: '81'
            },
            {
                label: 'Add Staff', value: '82'
            },

        ]
    }
    , {
        label: 'Testimonial', value: '9'
        , options: [
            {
                label: 'All Testimonial', value: '91'
            },
            {
                label: 'Add Testimonial', value: '92'
            },

        ]
    } , 
    {
        label: 'Result', value: '911'
        , options: [
            {
                label: 'Result Banner', value: '9111'
            },
            {
                label: 'Result Story', value: '9112'
            },

        ]
    }
    , {
        label: 'General Settings', value: '10'
        , options: [
            {
                label: 'Bannner', value: '101'
            },
            {
                label: 'Add Bannner', value: '1011'
            },
            {
                label: 'Logout Users', value: '102'
            },
            // {
            //     label: 'Logout Users', value: '102'
            // },
            {
                label: 'Subject', value: '103'
            },
            {
                label: 'Add Subject', value: '1033'
            },
            {
                label: 'Youtube', value: '104'
            },
            {
                label: 'Add Youtube', value: '1044'
            },
            {
                label: 'Telegram', value: '105'
            },
            {
                label: 'Add Telegram', value: '1055'
            },
            {
                label: 'Category', value: '106'
            },
            {
                label: 'Add Category', value: '1066'
            },
            {
                label: 'Sub Category', value: '107'
            }
            ,
            {
                label: 'Add Sub Category', value: '1077'
            }
            ,
            {
                label: 'Contact Us', value: '108'
            },
            {
                label: 'Add Contact Us', value: '1088'
            },
            {
                label: 'CTA Banner', value: '10001'
            },
            {
                label: 'Add CTA Banner', value: '10002'
            },

            {
                label: 'Help And Support', value: '109'
            },

        ]
    }, 
    {
        label: 'Store', value: '11'
        , options: [
            {
                label: 'Store Category', value: '111'
            },
            {
                label: 'Store Banner', value: '112'
            },
            {
                label: 'Store Featured Video', value: '113'
            },
            {
                label: 'Store Alerts', value: '114'
            },
            {
                label: 'All Product', value: '115'
            },
            {
                label: 'Add Product', value: '116'
            },
            {
                label: 'Store Order Report', value: '117'
            },
            {
                label: 'Store Purchase Report', value: '118'
            },
            {
                label: 'Store Wishlist', value: '119'
            },
            {
                label: 'Store Cart', value: '120'
            },
            {
                label: 'Store User', value: '1201'
            },
            {
                label: 'All Trashed Product', value: '1202'
            },
            {
                label: 'Store Coupon', value: '1203'
            },
            {
                label: 'Store Review', value: '1205'
            },
        ]
    },
    {
        label: 'CMS', value: '12'
        , options: [
            {
                label: 'All Blogs', value: '121'
            },
            {
                label: 'Add Blog', value: '122'
            },

        ]
    },
    {
        label: 'Community', value: '13'
        , options: [
            {
                label: 'All Community', value: '131'
            },
            // {
            //     label: 'Add Community', value: '132'
            // },

        ]
    },
    {
        label: 'Push Notification', value: '14'
        , options: [
            {
                label: 'All Notiification', value: '141'
            },
            {
                label: 'Add Notification', value: '142'
            },

        ]
    },
    {
        label: 'ScholarShip Test', value: '15'
        , options: [
            {
                label: 'All Scholarship Test', value: '151'
            },
            {
                label: 'Add ScholarShip Test', value: '152'
            },

        ]
    } , 
    {
        label: 'Cloud Storage', value: '171'
        , options: [
            {
                label: 'Cloud Storage', value: '171'
            },
            

        ]
    }

    
]




export const keyValueTabForTeacher =
    [

        {
            label: 'All Batches', value: '21'
        },
        {
            label: 'All Batch Community', value: '2311'
        },
        {
            label: 'All Batch Doubt', value: '2312'
        },
        {
            label: 'Add Batch', value: '22'
        },
        {
            label : 'Batch Feature' , value : '231'
        },
        {
            label: 'All Lecture', value: '23'
        },
        {
            label: 'Add Lecture', value: '24'
        },
        {
            label: 'Announcements', value: '25'
        },
        {
            label: 'All Test Series', value: '31'
        },
        {
            label: 'Add Test Series', value: '32'
        },
        {
            label: 'All Test', value: '33'
        },
        {
            label: 'Add Test', value: '34'
        },
        {
            label: 'All Quiz', value: '41'
        },
        {
            label: 'Add Quiz', value: '42'
        },
        {
            label: 'All Question', value: '43'
        },
        {
            label: 'Add Question', value: '44'
        },
        {
            label: 'All Coupon', value: '51'
        },
        {
            label: 'Add Coupon', value: '52'
        },

        {
            label: 'All Assignment', value: '71'
        },
        {
            label: 'Add Assignment', value: '72'
        },
        {
            label: 'All Blogs', value: '121'
        },
        {
            label: 'Add Blog', value: '122'
        },
        {
            label: 'Store Wishlist', value: '119'
        },
        {
            label: 'Store Cart', value: '120'
        },
        {
            label: 'Store User', value: '1201'
        },
        {
            label: 'All Trashed Product', value: '1202'
        },
        {
            label: 'Store Coupon', value: '1203'
        },

        {
            label: 'Help And Support', value: '109'
        },
        {
            label: 'Community', value: '131'
        },
        {
            label: 'All Notiification', value: '141'
        },
        {
            label: 'Add Notification', value: '142'
        },
        {
            label: 'All Scholarship Test', value: '151'
        },
        {
            label: 'Add ScholarShip Test', value: '152'
        },
        {
                label: 'Cloud Storage', value: '171'
            },
            {
                label: 'Channel', value: '8881'
            },
            {
                label: 'Add Channel', value: '8882'
            },
            {
                label: 'Shorts', value: '8883'
            },
            {
                label: 'Add Short', value: '8884'
            },
        // {
        //     label: 'Add Community', value: '132'
        // },

    ]
export const keyValueTab =

    [
        {
            label : "All" , value : 'all'
        },
        {
            label: 'All Users', value: '1111'
        },
        {
            label: 'Specific Users', value: '111112'
        },
        {
            label: 'Course Purchases', value: '1112'
        },
        {
            label: 'Course Orders', value: '11121'
        },
        {
            label: 'Test Series Purchases', value: '1113'
        },
        {
            label: 'Purchase Report', value: '1114'
        },
        {
            label: 'User Report', value: '1115'
        },
        {
            label: 'Call To Action', value: '1116'
        },
        {
            label: 'All Batches', value: '21'
        },
        {
            label: 'All Batch Community', value: '2311'
        },
        {
            label: 'All Batch Doubt', value: '2312'
        },
        {
            label: 'Add Batch', value: '22'
        },
        {
            label: 'All Lecture', value: '23'
        },
        {
            label: 'Add Lecture', value: '24'
        },
        {
            label: 'Announcements', value: '25'
        },
        {
            label: 'All Test Series', value: '31'
        },
        {
            label: 'Add Test Series', value: '32'
        },
        {
            label: 'All Test', value: '33'
        },
        {
            label: 'Add Test', value: '34'
        },
        {
            label: 'All Quiz', value: '41'
        },
        {
            label: 'Add Quiz', value: '42'
        },
        {
            label: 'All Question', value: '43'
        },
        {
            label: 'Add Question', value: '44'
        },
        {
            label: 'All Coupon', value: '51'
        },
        {
            label: 'Add Coupon', value: '52'
        },
        {
            label: 'Recorded Video', value: '61'
        },
        {
            label: 'Add Recorded Video', value: '611'
        },
        {
            label: 'Pathyakram', value: '62'
        },
        {
            label: 'Add Pathyakram', value: '622'
        },
        {
            label: 'Notes', value: '63'
        },
        {
            label: 'Add Notes', value: '633'
        },
        {
            label: 'Previous Year Question Papers', value: '64'
        },
        {
            label: 'Add Previous Year Question Papers', value: '644'
        },
        {
            label: 'AIR', value: '65'
        },
        {
            label: 'Add AIR', value: '655'
        },
        {
            label: 'News Clip', value: '66'
        },
        {
            label: 'Add News Clip', value: '666'
        },
        {
            label: 'Teacher Generated Content Of Batch', value: '67'
        },
        // {
        //     label: 'Add Teacher Generated Content Of Batch', value: '677'
        // },
        {
            label: 'Alerts', value: '68'
        },
        {
            label: 'Add Alerts', value: '688'
        },
        {
            label: 'All Assignment', value: '71'
        },
        {
            label: 'Add Assignment', value: '72'
        },
        {
            label: 'All Staff', value: '81'
        },
        {
            label: 'Add Staff', value: '82'
        },
        {
            label: 'All Testimonial', value: '91'
        },
        {
            label: 'Add Testimonial', value: '92'
        },
        {
            label: 'Result Banner', value: '9111'
        },
        {
            label: 'Result Story', value: '9112'
        },
        {
            label: 'Bannner', value: '101'
        },
        {
            label: 'Add Bannner', value: '1011'
        },
        {
            label: 'Logout Users', value: '102'
        },
        // {
        //     label: 'Add Logout Users', value: '1022'
        // },
        {
            label: 'Subject', value: '103'
        },
        {
            label: 'Add Subject', value: '1033'
        },
        {
            label: 'Youtube', value: '104'
        },
        {
            label: 'Add Youtube', value: '1044'
        },
        {
            label: 'Telegram', value: '105'
        },
        {
            label: 'Add Telegram', value: '1055'
        },
        {
            label: 'Category', value: '106'
        },
        {
            label: 'Add Category', value: '1066'
        },
        {
            label: 'Sub Category', value: '107'
        }
        ,
        {
            label: 'Add Sub Category', value: '1077'
        }
        ,
        {
            label: 'Contact Us', value: '108'
        },
        {
            label: 'Add Contact Us', value: '1088'
        },

        {
            label: 'Help And Support', value: '109'
        },
        {
            label: 'Store Category', value: '111'
        },
        {
            label: 'Store Banner', value: '112'
        },
        {
            label: 'Store Featured Video', value: '113'
        },
        {
            label: 'Store Alerts', value: '114'
        },
        {
            label: 'All Product', value: '115'
        },
        {
            label: 'Add Product', value: '116'
        },
        {
            label: 'Store Order Report', value: '117'
        },
        {
            label: 'Store Purchase Report', value: '118'
        }
        ,
        {
            label: 'All Blogs', value: '121'
        },
        {
            label: 'Add Blog', value: '122'
        },
        {
            label: 'Store Wishlist', value: '119'
        },
        {
            label: 'Store Cart', value: '120'
        },
        {
            label: 'Store User', value: '1201'
        },
        {
            label: 'All Trashed Product', value: '1202'
        },
        {
            label: 'Store Coupon', value: '1203'
        },
        {
            label: 'Store Review', value: '1205'
        },
        {
            label: 'Community', value: '131'
        },
        {
            label: 'All Notiification', value: '141'
        },
        {
            label: 'Add Notification', value: '142'
        },
        {
            label: 'All Scholarship Test', value: '151'
        },
        {
            label: 'Add ScholarShip Test', value: '152'
        },
        {
            label: 'CTA Banner', value: '10001'
        },
        {
            label: 'Add CTA Banner', value: '10002'
        },
        {
                label: 'Cloud Storage', value: '171'
        },
        {
            label: 'Channel', value: '8881'
        },
        {
                label: 'Add Channel', value: '8882'
            },
            {
                label: 'Shorts', value: '8883'
            },
            {
                label: 'Add Short', value: '8884'
            },
        // {
        //     label: 'Add Community', value: '132'
        // },

    ]



export default { options, keyValueTab, optionsForTeacher, keyValueTabForTeacher };