import {
  Navigate,
  useRoutes,
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import DashboardLayout from "./layouts/dashboard/index.js";
// import Blog from './pages/Blog';
import Login from "./pages/Login.js";
import NotFound from "./pages/Page404.js";
// import Products from './pages/Products';
import DashboardApp from "./pages/DashboardApp.js";
import LoginTeacher from "./pages/LoginTeacher.js";
import Student from "./pages/User.js";
import Batch from "./sections/@dashboard/batch/Batch.js";
import Test from "./sections/@dashboard/testSeries/test.js";
import AddTestSeries from "./sections/@dashboard/testSeries/AddTestSeries.js";
import AddBatch from "./sections/@dashboard/batch/AddBatch.js";
import BatchDetails from "./sections/@dashboard/batch/BatchDetails.js";
import BatchDetails1 from "./sections/@dashboard/batch/BatchDetails1.js";
import Staff from "./sections/@dashboard/manageStaff/Staff.js";
import LectureForm from "./sections/@dashboard/batch/LectureForm.js";
import LiveLecture from "./sections/@dashboard/batch/LiveLecture.js";
import Banner from "./sections/@dashboard/generalSettiing/Banner.js";
import ContactUs from "./sections/@dashboard/generalSettiing/ContactUs.js";
import StudentBatches from "./sections/@dashboard/user/StudentBatches.js";
import SubAdmin from "./sections/@dashboard/manageStaff/SubAdmin.js";
import Moderators from "./sections/@dashboard/manageStaff/Moderators.js";
import Assignment from "./sections/@dashboard/assignmennt/Assignment.js";
import Testimonial from "./sections/@dashboard/testimonial/Testimonial.js";
import AIR from "./sections/@dashboard/resources/AIR.js";
import CustomNotification from "./sections/@dashboard/resources/CustomNotification.js";
import AddCustomNotification from "./sections/@dashboard/resources/AddCustomNotification.js";
import NewsClip from "./sections/@dashboard/resources/NewsClip.js";
import TeacherGenerated from "./sections/@dashboard/resources/TeacherGenerated.js";
import Pathyakram from "./sections/@dashboard/resources/Pathyakram.js";
import Notes from "./sections/@dashboard/resources/Notes.js";
import LiveStream from "./sections/@dashboard/livestream/LiveStream.js";
import Category from "./sections/@dashboard/generalSettiing/Category.js";
import Youtube from "./sections/@dashboard/generalSettiing/Youtube.js";
import HelpAndSupport from "./sections/@dashboard/generalSettiing/HelpAndSupport.js";
import Telegram from "./sections/@dashboard/generalSettiing/Telegram.js";
import Subject from "./sections/@dashboard/generalSettiing/Subject.js";
import AddResources from "./sections/@dashboard/resources/AddResources.js";
import Resources from "./sections/@dashboard/resources/Resources.js";
import LectureResources from "./sections/@dashboard/batch/LectureResources.js";
import Profile from "./sections/@dashboard/profile/Profile.js";
import Messaging from "./sections/@dashboard/messaging/Messaging.js";
import LogoutUsers from "./sections/@dashboard/generalSettiing/LogoutUsers.js";
import Recorded from "./sections/@dashboard/resources/Recorded.js";
import AddRecordedVideo from "./sections/@dashboard/resources/AddRecordedVideo.js";
// import RecordedTable from './sections/@dashboard/resources/RecordedTable';
import RecordedLectures from "./sections/@dashboard/batch/RecordedLectures.js";
import StudentList from "./sections/@dashboard/user/StudentList.js";
import StudentProfile from "./sections/@dashboard/user/StudentProfile.js";
import TeacherProfile from "./sections/@dashboard/manageStaff/TeacherProfile.js";
import AssignTeacherToBatch from "./sections/@dashboard/batch/AssignTeacherToBatch.js";
import UpdateBatchDetails from "./sections/@dashboard/batch/UpdateBatchDetails.js";
import UpdateLectureDetails from "./sections/@dashboard/batch/UpdateLectureDetails.js";
import TestSeriesDetails from "./sections/@dashboard/testSeries/TestSeriesDetails.js";
import AddTest from "./sections/@dashboard/testSeries/AddTest.js";
import UpdateTestSeries from "./sections/@dashboard/testSeries/UpdateTestSeries.js";
import AttemptedTest from "./sections/@dashboard/testSeries/AttemptedTest.js";
import StudentTestSeries from "./sections/@dashboard/testSeries/StudentTestSeries.js";
import StudentTestSeries1 from "./sections/@dashboard/testSeries/StudentTestSeries1.js";
import StudentTestSeriesList from "./sections/@dashboard/testSeries/StudentTestSeriesList.js";
import UpdateTest from "./sections/@dashboard/testSeries/UpdateTest.js";
// import APP from './backgroundUpload/APP';
// import FlutterLiveStream from './flutterLiveStream/FlutterLiveStream';
import UpdatePermission from "./sections/@dashboard/manageStaff/Components/UpdatePermission.js";
import UpdateStaff from "./sections/@dashboard/manageStaff/UpdateStaff.js";
// import QuizQuestions from "./sections/@dashboard/quiz/QuizQuestions";
import Quiz from "./sections/@dashboard/quiz/Quiz.js";
import AddQuizQuestion from "./sections/@dashboard/quiz/AddQuizQuestion.js";
import AddQuiz from "./sections/@dashboard/quiz/AddQuiz.js";
// import EditQuestion from "./sections/@dashboard/quiz/Quiz";
import EditQuestion from "./sections/@dashboard/quiz/EditQuestion.js";
import UpdateQuiz from "./sections/@dashboard/quiz/UpdateQuiz.js";
import IssuesTable from "./sections/@dashboard/quiz/IssuesTable.js";
import DoubtsTable from "./sections/@dashboard/quiz/DoubtsTable.jsx";
import QuizTable from "./sections/@dashboard/quiz/QuizTable.js";
import UpdateQuizQuestions from "./sections/@dashboard/quiz/UpdateQuizQuestions.js";
import QuizStudents from "./sections/@dashboard/quiz/QuizStudents.js";
import QuizSResults from "./sections/@dashboard/quiz/QuizResults.js";
import StudentResults from "./sections/@dashboard/quiz/StudentResults.js";
import AllCoupon from "./sections/@dashboard/coupon/AllCoupon.js";
import AddCoupon from "./sections/@dashboard/coupon/AddCoupon.js";
import Oath2callback from "./sections/@dashboard/Oath2callback.js";
import LeaderBoard from "./sections/@dashboard/quiz/LeaderBoard.js";
import SubCategory from "./sections/@dashboard/generalSettiing/SubCategory.js";
import PreviousYearQuestionPapers from "./sections/@dashboard/resources/PreviousYearQuestionPapers.js";
import Students from "./pages/Students.js";
import UserReport from "./pages/UserReport.js";
import YotubeLive from "./sections/@dashboard/batch/YotubeLive.js";
import UpdateBannerDetails from "./sections/@dashboard/generalSettiing/UpdateBannerDetails.js";
import { User } from "./context/context.js";
import CenterLectureForm from "./sections/@dashboard/batch/CenterLectureForm.js";
import AddCenterLectureResources from "./sections/@dashboard/batch/AddCenterLectureResources.js";

// store 
import StoreCategory from "./sections/@dashboard/store/StoreCategory.js";
import StoreBanner from "./sections/@dashboard/store/StoreBanner.js";
import StoreFeaturedVideo from "./sections/@dashboard/store/StoreFeaturedVideo.js";
import StoreProduct from "./sections/@dashboard/store/StoreProduct.js";
import AddStoreProduct from "./sections/@dashboard/store/AddStoreProduct.js";
import AddStoreCategory from "./sections/@dashboard/store/AddStoreCategory.js";
import UpdateCoupon from "./sections/@dashboard/coupon/UpdateCoupon.js";
import StoreOrdersTable from "./sections/@dashboard/store/StoreOrdersTable.js";
import StorePuchaseReport from "./sections/@dashboard/store/StorePuchaseReport.js";
import StoreAlert from "./sections/@dashboard/store/StoreAlert.js";
import CTA from "./sections/@dashboard/user/CTA.js";
import User1 from "./pages/User1.js";
import UserReport1 from "./pages/UserReport1.js";
import Students1 from "./pages/Students1.js";
import AllCoupon1 from "./sections/@dashboard/coupon/AllCoupon1.js";
import Draggable from "./sections/@dashboard/store/Draggable.js";
import DraggableComponent from "./sections/@dashboard/store/Draggable.js";
import AnnouncementTable from "./sections/@dashboard/announcement/AnnouncementTable.js";
import AddAnnouncement from "./sections/@dashboard/announcement/AddAnnouncement.js";
import Announcement from "./sections/@dashboard/announcement/Announcement.js";
import UpdateCategory from "./sections/@dashboard/store/UpdateCategory.js";
import UpdateStoreBanner from "./sections/@dashboard/store/UpdateStoreBanner.js";
import UpdateStoreAlerts from "./sections/@dashboard/store/UpdateStoreAlerts.js";
import UpdateStoreFeaturedVideo from "./sections/@dashboard/store/UpdateStoreFeaturedVideo.js";
import UpdateStoreProduct from "./sections/@dashboard/store/UpdateStoreProducts.js";
import AddCenterAnnouncements from "./sections/@dashboard/batch/addCenterAnnouncements.js";
import ScholarshipTestTable from "./sections/@dashboard/scholarshipTest/ScholarshipTestTable.js";
import AddScholarshipTest from "./sections/@dashboard/scholarshipTest/AddScholarshipTest.js";
import EditScholarshipTest from "./sections/@dashboard/scholarshipTest/EditScholarshipTest.js";
import Batch1 from "./sections/@dashboard/batch/Batch1.js";
import StudentBatches1 from "./sections/@dashboard/user/StudentBatches1.js";
// import Batch1 from "./sections/@dashboard/batch/Batch1.js";
import ProductProfilePage from "./sections/@dashboard/store/ProductProfilePage.js";
import SchlorshipTestUser from "./sections/@dashboard/scholarshipTest/SchlorshipTestUser.js";
import UpdateYoutube from "./sections/@dashboard/generalSettiing/UpdateYoutube.js";
import { element } from "prop-types";
import AddBlog from "./sections/@dashboard/cms/AddBlog.js";
import BlogTable from "./sections/@dashboard/cms/BlogTable.js";
import BlogProfile from "./sections/@dashboard/cms/BlogProfile.js";
import UpdateBlog from "./sections/@dashboard/cms/UpdateBlog.js";
import UpdateLectureResource from "./sections/@dashboard/batch/UpdateLectureResource.js";
import AppLive from "./sections/@dashboard/batch/AppLive.js";
import StoreWishlistTable from "./sections/@dashboard/store/StoreWishlistTable.js";
import StoreCartsTable from "./sections/@dashboard/store/StoreCartsTable.js";
import Community from "./sections/@dashboard/community/Community.js";
import EditCommunity from "./sections/@dashboard/community/EditCommunity.js";
import CommunityProfile from "./sections/@dashboard/community/CommunityProfile.js";
import StoreOrder from "./sections/@dashboard/store/StoreOrder.js";
import StoreUser from "./sections/@dashboard/store/StoreUser.js";
import StoreTrashProduct from "./sections/@dashboard/store/StoreTrashProduct.js";
import UpdateBatchCategory from "./sections/@dashboard/generalSettiing/UpdateBatchCateory.js";
import EditSubject from "./sections/@dashboard/generalSettiing/EditSubject.js";
import Notification from "./sections/@dashboard/notification/Notification.js";
import AddNotification from "./sections/@dashboard/notification/AddNotification.js";
import { useEffect, useState } from "react";
import { setDate } from "date-fns";
import getAccess from "./utils/getAccess.js";
import CTABanner from "./sections/@dashboard/generalSettiing/CTABanner.js";
import StoreCoupon from "./sections/@dashboard/store/StoreCoupon.js";
import StudentList1 from "./sections/@dashboard/user/StudentList1.js";
import LogsTable from "./sections/@dashboard/user/LogsTable.js";
import LectureRoomTable from "./sections/@dashboard/batch/LectureRoomTable.js";
import FAQsTable from "./sections/@dashboard/generalSettiing/FAQsTable.js";
import FAQs from "./sections/@dashboard/generalSettiing/FAQs.js";
import AssignFaqs from "./sections/@dashboard/batch/AssignFaqs.js";
import AssignMentorToLectureRoom from "./sections/@dashboard/batch/AssignMentorToLectureRoom.js";
import BucketTable from "./sections/@dashboard/bucket/BucketTable.js";
import FolderPage from "./sections/@dashboard/bucket/FolderPage.js";
import SearchQueryTable from "./sections/@dashboard/store/SearchQueryTable.js";
import LectureReportTable from "./sections/@dashboard/batch/LectureReportTable.js";
import DeepLinkTable from "./sections/@dashboard/deeplink/DeepLinkTable.js";
import AddDeepLink from "./sections/@dashboard/deeplink/AddDeepLink.js";
import EbookTable from "./sections/@dashboard/ebook/EbookTable.js";
import AddEbook from "./sections/@dashboard/ebook/AddEbook.js";
import AddChapter from "./sections/@dashboard/ebook/AddChapter.js";
import ChapterTable from "./sections/@dashboard/ebook/ChapterTable.js";
import TopicTable from "./sections/@dashboard/ebook/TopicTable.js";
import AddTopic from "./sections/@dashboard/ebook/AddTopic.js";
import  EbookPurchased  from "./sections/@dashboard/user/EbookPurchased.js";
import EbookStudentList from "./sections/@dashboard/user/EbookStudentList.js";
import UpdateSubCategory from "./sections/@dashboard/generalSettiing/UpdateSubCategory.js";
import StoreReview from "./sections/@dashboard/store/StoreReview.js";
import ValidityGrid from "./sections/@dashboard/batch/helperComponents/ValidityGrid.js";
import SpecificUsers from "./pages/SpecificUsers.js";
import AddQuizForBatches from "./sections/@dashboard/quiz/AddQuizForBatches.js";
import EditMultipleLecture from "./sections/@dashboard/batch/EditMultipleLecture.js";
import AddMultipleLectureResource from "./sections/@dashboard/batch/AddMultipleLectureResource.js";
import BatchCommunity from "./sections/@dashboard/batch/BatchCommunity.js";
import BatchCommunityCommentTable from "./sections/@dashboard/batch/BatchCommunityCommentTable.js";
import BatchCommunityReplyTable from "./sections/@dashboard/batch/BatchCommunityReplyTable.js";
import Feature from "./sections/@dashboard/batch/features/Feature.js";
import EditFeature from "./sections/@dashboard/batch/features/EditFeature.js";
import BatchDoubtTable from "./sections/@dashboard/batch/batchDoubts/BatchDoubtTable.js";
import BatchDoubtCommentTable from "./sections/@dashboard/batch/batchDoubts/BatchDoubtCommentTable.js";
import AddQuestionForQuizes from "./sections/@dashboard/quiz/AddQuestionForQuizes.js";
import AddQuestionForMultiQuizes from "./sections/@dashboard/quiz/AddQuestionForMultiQuizes.js";
import Channel from "./sections/@dashboard/quickLearning/Channel.js";
import Short from "./sections/@dashboard/quickLearning/Short.js";
import ValidityFeature from "./sections/@dashboard/batch/validityFeature/ValidityFeature.js";
import AllBatchCommunity from "./sections/@dashboard/batch/AllBatchCommunity.js";
import AllBatchDoubt from "./sections/@dashboard/batch/batchDoubts/AllBatchDoubt.js";
import ResultBanner from "./sections/@dashboard/result/ResultBanner.js";
import ResultStory from "./sections/@dashboard/result/ResultStory.js";
import CourseOrderTable from "./sections/@dashboard/user/CourseOrderTable.js";


// ----------------------------------------------------------------------

export default function Router() {
  const d = getAccess();
  const role = d?.role == 'admin' ? "Admin" : d?.role;

  const LoggedIn = localStorage.getItem("isLoggedIn");
  const assignBatch = localStorage.getItem('assignBatch');
  // console.log(d)
  // console.log(assignBatch)
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      // eslint-disable-next-line no-sparse-arrays
      children: [
        { path: "app", element: <DashboardApp /> },
        { path: "product_profile/:id", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <ProductProfilePage /> },
        // { path: "product_profile/:id", element: <ProductProfilePage /> },
        // { path: "message", element: <Messaging /> },
        // { path: "logoutUsers", element: <LogoutUsers /> },
        { path: "logoutUsers", element: (!d?.access.includes('all') && !d?.access?.includes('102')) ? <Navigate to="/404" replace /> : <LogoutUsers /> },
        // { path: "students", element: <Student /> },
        { path: "teacher/teacherProfile/:data", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <TeacherProfile /> },
        { path: "students/studentsProfile/:data", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <StudentProfile /> },
        { path: "profile", element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <Profile /> },

        // { path: "studentBatches", element: <StudentBatches /> },
        {
          path: "studentBatches/studentList/:active/:batchId",
          element: <StudentList1 />,
        },
        // { path: 'products', element: <Products /> },
        { path: 'youtubeLive/:lectId/:lectTitle/:lectDesc/:ytUrl', element: <YotubeLive /> },
        { path: 'appLive/:lectId/:lectTitle/:lectDesc/:ytUrl', element: <AppLive /> },
        // { path: "batches", element: <Batch /> },
        {
          path: "batches/addTeacherToBatch/:batchId",
          element: <AssignTeacherToBatch />,
        },
        {
          path: "faqsAssign/:from/:id",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AssignFaqs />,
        },
        {
          path: "assignMentor/:id",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AssignMentorToLectureRoom />,
        },
        {
          path: "batches/updateBatchDetails/:batchId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateBatchDetails />,
        },
        // update banner Routes
        {
          path: "banner/updateBannerDetails/:bannerName/:bannerId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateBannerDetails />
        },
        {
          path: 'callToAction', element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <CTA />
        },
        // store 




        { path: "helpAndSupport", element: (!d?.access.includes('all') && !d?.access?.includes('109')) ? <Navigate to="/404" replace /> : <HelpAndSupport /> },
        { path: "logs", element: (!d?.access.includes('all') && !d?.access?.includes('161')) ? <Navigate to="/404" replace /> : <LogsTable /> },
        { path: "buckets", element: (!d?.access.includes('all') && !d?.access?.includes('171')) ? <Navigate to="/404" replace /> : <BucketTable /> },
        { path: "bucketFolder/:prefix", element: (!d?.access.includes('all') && !d?.access?.includes('171')) ? <Navigate to="/404" replace /> : <FolderPage /> },

        { path: "faqs", element: (!d?.access.includes('all') && !d?.access?.includes('10003')) ? <Navigate to="/404" replace /> : <FAQs /> },
        // { path: "addBatch", element: <AddBatch /> },
        // { path: "batchDetails/:Id", element: <BatchDetails /> },
        { path: "batchDetails/:Id", element: (!d?.access.includes('all') && !d?.access.includes('21')) ? <Navigate to="/404" replace /> : <BatchDetails1 /> },
        {
          path: "batchDetails/updateLectureDetails/:batchId/:lectId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateLectureDetails />,
        },
        {
          path: "updateMultipleLectures",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditMultipleLecture />,
        },
        {
          path: "lectureRooms/:lectId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <LectureRoomTable />,
        },
        {
          path: "lectureReport/:lectId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <LectureReportTable />,
        },
        {
          path: "updateLectureResources/:id/:from",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateLectureResource />,
        },
        {
          path: "batchDetails/addLecture/:Id/:batchName",
          element: (!d?.access.includes('all') && !d?.access?.includes('24')) ? <Navigate to="/404" replace /> : <LectureForm />,
        },
        {
          path: "batchDetails/addCenterLecture",
          element: (!d?.access.includes('all') && !d?.access?.includes('24')) ? <Navigate to="/404" replace /> : <CenterLectureForm />
        },
        {
          path: "batchDetails/addCenterLectureResources",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddCenterLectureResources />
        },
        {
          path: "addMultipleLectureResources",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddMultipleLectureResource />
        },
        
        
        {
          path: "batchDetails/liveLecture/:Id/:batchName",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <LiveLecture />,
        },
        // { path: "test", element: <Test /> },
        {
          path: "test/testSeriesDetails/updateTestDetails/:testSeriesId/:testId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateTest />,
        },

        // { path: "studentTestSeries", element: <StudentTestSeries /> },
        {
          path: "studentTestSeries/studentList/:active/:testSeriesId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <StudentTestSeriesList />,
        },
        {
          path: "test/updateTestSeries/:testSeriesId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateTestSeries />,
        },
        {
          path: "test/testSeriesDetails/:testSeriesId",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <TestSeriesDetails />,
        },
        {
          path: "test/editquestion/:testSeriesId/:questionId/:data",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditQuestion />,
        },
        {
          path: "test/editquestion/",
          element: (!d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditQuestion />,
        },
        {
          path: "test/testSeriesDetails/attemptedTest/:testSeriesId",
          element: <AttemptedTest />,
        },
        {
          path: "test/testSeriesDetails/addTest/:testSeriesId",
          element: (!d?.access.includes('all') && !d?.access?.includes('34')) ? <Navigate to="/404" replace /> : <AddTest />,
        },
        { path: "recordedLectures", element: <RecordedLectures /> },
        // { path: "addTest", element: <AddTestSeries /> },
        // { path: "staff", element: <Staff /> },
        { path: "staff/updateStaff/:teacherId", element: (!d?.access.includes('all')) ? <Navigate to="/404" replace /> : <UpdateStaff /> },
        {
          path: "staff/updatePermission/:teacherId",
          element: (!d?.access.includes('all')) ? <Navigate to="/404" replace /> : <UpdatePermission />,
        },
        { path: "moderators", element: <Moderators /> },
        { path: "subAdmin", element: <SubAdmin /> },
        // { path: "banner", element: <Banner /> },
        // { path: "youtube", element: <Youtube /> },
        // { path: "telegram", element: <Telegram /> },
        // { path: "contactUs", element: <ContactUs /> },
        // { path: "category", element: <Category /> },
        // { path: "subCategory", element: <SubCategory /> },
        // { path: "subject", element: <Subject /> },
        { path: 'updateCategory/:id', element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateBatchCategory /> },
        { path: 'editSubject/:id', element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditSubject /> },
        { path: "updateYoutubeVideo/:youtubeId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateYoutube /> },
        { path: "addResources", element: <AddResources /> },
        // { path: "customNotification", element: <CustomNotification /> },
        { path: "addCustomNotification", element: <AddCustomNotification /> },
        { path: "resources", element: <Resources /> },
        {
          path: "lectureResources/:lectId/:batchId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <LectureResources />,
        },
        // { path: "assignment", element: <Assignment /> },
        // { path: "testimonial", element: <Testimonial /> },
        // { path: "pathyakram", element: <Pathyakram /> },
        // { path: "recorded", element: <Recorded /> },
        { path: "addRecorded/:lectId/:batchId", element: (!d?.access?.includes('all') && !d?.access?.includes('611')) ? <Navigate to="/404" replace /> : <AddRecordedVideo /> },
        // { path: "air", element: <AIR /> },
        // { path: "newsClip", element: <NewsClip /> },
        // { path: "notes", element: <Notes /> },
        // { path: "previousYearQuestionPapers", element: <PreviousYearQuestionPapers /> },
        // { path: "quiz", element: <Quiz /> },
        { path: "quiz/doubts/:quizName/:quizId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <DoubtsTable /> },
        { path: "quiz/issues/:quizName/:quizId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <IssuesTable /> },
        {
          path: "quiz/quizStudents/:quizName/:quizId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <QuizStudents />,
        },
        {
          path: "quiz/leaderboard/:quizId/:quizName",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <LeaderBoard />
        },
        {
          path: "quiz/quizResults/:quizName/:quizId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <QuizSResults />,
        },
        {
          path: "quiz/editquestion/:quizId/:selectedIdOfQuestion/:quizTitle",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditQuestion />,
        },
        // { path: "purchaseReport", element: <Students /> },
        { path: "purchaseReport", element: (!d?.access?.includes('all') && !d?.access?.includes('1114')) ? <Navigate to="/404" replace /> : <Students1 /> },
        { path: "userReport", element: (!d?.access?.includes('all') && !d?.access?.includes('1115')) ? <Navigate to="/404" replace /> : <UserReport1 /> },
        // { path: "userReport", element: <UserReport /> },



        //Store
        { path: "storeCategory", element: (!d?.access?.includes('all') && !d?.access?.includes('111')) ? <Navigate to="/404" replace /> : <StoreCategory /> },

        // { path: "addStoreCategory", element: role !== 'Admin' && !d?.access?.includes('111') ? <Navigate to="/404" replace /> : <AddStoreCategory /> },
        { path: "storeBanner", element: (!d?.access?.includes('all') && !d?.access?.includes('112')) ? <Navigate to="/404" replace /> : <StoreBanner /> },
        { path: "storeFeaturedVideo", element: (!d?.access?.includes('all') && !d?.access?.includes('113')) ? <Navigate to="/404" replace /> : <StoreFeaturedVideo /> },
        {
          path: "storeAllProducts", element: (!d?.access?.includes('all') && !d?.access?.includes('115')) ? <Navigate to="/404" replace /> : <StoreProduct />
        },
        { path: "storeUser", element: (!d?.access?.includes('all') && !d?.access?.includes('1201')) ? <Navigate to="/404" replace /> : <StoreUser /> },
        { path: 'storeTrashProduct', element: (!d?.access?.includes('all') && !d?.access?.includes('1202')) ? <Navigate to="/404" replace /> : <StoreTrashProduct /> },
        { path: 'storeCoupon', element: (!d?.access?.includes('all') && !d?.access?.includes('1203')) ? <Navigate to="/404" replace /> : <StoreCoupon /> },
        { path: "addStoreProduct", element: (!d?.access?.includes('all') && !d?.access?.includes('116')) ? <Navigate to="/404" replace /> : <AddStoreProduct /> },
        { path: "storeOrders", element: (!d?.access?.includes('all') && !d?.access?.includes('117')) ? <Navigate to="/404" replace /> : <StoreOrder /> },
        { path: 'storePurchaseReport', element: (!d?.access?.includes('all') && !d?.access?.includes('118')) ? <Navigate to="/404" replace /> : <StorePuchaseReport /> },
        { path: "storeAlert", element: (!d?.access?.includes('all') && !d?.access?.includes('114')) ? <Navigate to="/404" replace /> : <StoreAlert /> },
        { path: "storeWishlist", element: (!d?.access?.includes('all') && !d?.access?.includes('119')) ? <Navigate to="/404" replace /> : <StoreWishlistTable /> },
        { path: "storeCarts", element: (!d?.access?.includes('all') && !d?.access?.includes('120')) ? <Navigate to="/404" replace /> : <StoreCartsTable /> },
        { path: 'searchQuery/:type', element: (!d?.access?.includes('all') && !d?.access?.includes('1204') && !d?.access?.includes('1117')) ? <Navigate to="/404" replace /> : <SearchQueryTable /> },
        { path: 'storeReview', element: (!d?.access?.includes('all') && !d?.access?.includes('1205')) ? <Navigate to="/404" replace /> : <StoreReview /> },




        //User
        {
          // path: "students", element: role !== 'Admin' && !d?.access?.includes('11') ? <Navigate to="/404" replace /> : <Student />
          path: "students", element: (!d?.access?.includes('all') && !d?.access?.includes('1111')) ? <Navigate to="/404" replace /> : <User1 />

        },
        {
          // path: "students", element: role !== 'Admin' && !d?.access?.includes('11') ? <Navigate to="/404" replace /> : <Student />
          path: "specificStudents", element: (!d?.access?.includes('all') && !d?.access?.includes('111112')) ? <Navigate to="/404" replace /> : <SpecificUsers />

        },
        {
          path: "drag/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateCategory />

        },
        {
          path: "updateStoreBanner/:bannerId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateStoreBanner />

        },
        {
          path: "updateStoreProduct/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateStoreProduct />

        },
        {
          path: "updateStoreAlerts/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateStoreAlerts />

        },
        {
          path: "updateStoreFeatureVideos/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateStoreFeaturedVideo />

        },
        {
          // path: "storeBanner/:id", element: <UpdateStoreBanner />

        },
        // {
        //   path: "studentBatches", element: role !== 'Admin' && !d?.access?.includes('12') ? <Navigate to="/404" replace /> : <StudentBatches />

        // },
        {
          path: "studentBatches", element: (!d?.access?.includes('all') && !d?.access?.includes('1112')) ? <Navigate to="/404" replace /> : <StudentBatches1 />

        },
        {
          path: "courseOrders", element: (!d?.access?.includes('all') && !d?.access?.includes('11121')) ? <Navigate to="/404" replace /> : <CourseOrderTable />

        },
        {
          path: "updateCategory", element: role !== 'Admin' && <Students />

        }
        ,
        // {
        //   path: "studentTestSeries", element: role !== 'Admin' && !d?.access?.includes('13') ? <Navigate to="/404" replace /> : <StudentTestSeries />

        // }
        {
          path: "studentTestSeries", element: (!d?.access?.includes('all') && !d?.access?.includes('1113')) ? <Navigate to="/404" replace /> : <StudentTestSeries1 />

        }
        ,
        {
          path: "purchaseReport", element: (!d?.access?.includes('all') && !d?.access?.includes('1114')) ? <Navigate to="/404" replace /> : <Students />

        }
        ,
        {
          path: "userReport", element: (!d?.access?.includes('all') && !d?.access?.includes('1115')) ? <Navigate to="/404" replace /> : <UserReport />
          // path: "userReport", element: role !== 'Admin' && !d?.access?.includes('15') ? <Navigate to="/404" replace /> : <UserReport1 />

        }
        ,

        //Batch
        // {
        //   path: "batches", element: (role !== 'Admin' && !d?.access?.includes('21') && assignBatch?.length == 0) ? <Navigate to="/404" replace /> : <Batch />

        // },
        {
          path: "blogs", element: (!d?.access?.includes('all') && !d?.access?.includes('121')) ? <Navigate to="/404" replace /> : <BlogTable />

        },
        {
          path: "community", element: (!d?.access?.includes('all') && !d?.access?.includes('131')) ? <Navigate to="/404" replace /> : <Community />

        },
        {
          path: "community/:postId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditCommunity />

        },
        { path: "notification", element: (!d?.access?.includes('all') && !d?.access?.includes('141')) ? <Navigate to="/404" replace /> : <Notification /> },
        { path: 'createNotification', element: (!d?.access?.includes('all') && !d?.access?.includes('142')) ? <Navigate to="/404" replace /> : <AddNotification /> },
        {
          path: "communityProfile/:postId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <CommunityProfile />

        },
        {
          path: "addBlog", element: (!d?.access?.includes('all') && !d?.access?.includes('122')) ? <Navigate to="/404" replace /> : <AddBlog />

        },
        {
          path: "blog/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <BlogProfile />

        },
        {
          path: "updateBlog/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateBlog />

        },
        {
          path: "batches", element: (!d?.access?.includes('all') && !d?.access?.includes('21') && (parseInt(assignBatch) == 0 ? false : assignBatch?.length > 0 ? true : false)) ? <Navigate to="/404" replace /> : <Batch1 />

        },
        {
          path: "validityOfBatches/:batchId", element: (!d?.access?.includes('all') && !d?.access?.includes('21')) ? <Navigate to="/404" replace /> : <ValidityGrid />
        },
        {
          path: "validityFeaturesOfBatches/:batchId", element: (!d?.access?.includes('all') && !d?.access?.includes('21')) ? <Navigate to="/404" replace /> : <ValidityFeature />
        },

        {
          path: "batchCommunity/:batchId", element: (!d?.access?.includes('all') && !d?.access?.includes('21')) ? <Navigate to="/404" replace /> : <BatchCommunity />
        },
        {
          path: "batchCommunity/comments/:batchCommunityId", element: (!d?.access?.includes('2311') && !d?.access?.includes('21')) ? <Navigate to="/404" replace /> : <BatchCommunityCommentTable />
        },
        {
          path: "batchCommunity/comments/replyComments/:commentId", element: (!d?.access?.includes('2311') && !d?.access?.includes('21')) ? <Navigate to="/404" replace /> : <BatchCommunityReplyTable />
        },

        {
          path: "batchDoubt/:batchId", element: (!d?.access?.includes('all') && !d?.access?.includes('21')) ? <Navigate to="/404" replace /> : <BatchDoubtTable />
        },
        {
          path: "batchDoubt/comments/:batchDoubtId", element: (!d?.access?.includes('all') && !d?.access?.includes('2312')) ? <Navigate to="/404" replace /> : <BatchDoubtCommentTable />
        },
        {
          path: "allBatchCommunity", element: (!d?.access?.includes('all') && !d?.access?.includes('2311')) ? <Navigate to="/404" replace /> : <AllBatchCommunity />
        },
        {
          path: "allBatchDoubt", element: (!d?.access?.includes('all') && !d?.access?.includes('2312')) ? <Navigate to="/404" replace /> : <AllBatchDoubt />
        },

        {
          path: "addBatch", element: (!d?.access?.includes('all') && !d?.access?.includes('22')) ? <Navigate to="/404" replace /> : <AddBatch />

        },
        {
          path: "announcements", element: (role !== 'Admin') ? <Navigate to="/404" replace /> : <Announcement />

        },
        {
          path: "addAnnouncement", element: role !== 'Admin' ? <Navigate to="/404" replace /> : <AddAnnouncement />

        },
        {
          path: "announcements", element: (!d?.access?.includes('all') && !d?.access?.includes('25')) ? <Navigate to="/404" replace /> : <Announcement />

        },
        {
          path: "addAnnouncement", element: (!d?.access?.includes('all') && !d?.access?.includes('25') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddAnnouncement />

        },
        {
          path: "addCenterAnnouncement", element: (!d?.access?.includes('all') && !d?.access?.includes('25') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddCenterAnnouncements />

        },
        {
          path: "batchFeature", element: (!d?.access?.includes('all') && !d?.access?.includes('231') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <Feature />

        },
        {
          path: "editBatchFeature/:id", element: (!d?.access?.includes('all') && !d?.access?.includes('231') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditFeature />

        },
        {
          path: "scholarshipTests", element: (!d?.access?.includes('all') && !d?.access?.includes('151')) ? <Navigate to="/404" replace /> : <ScholarshipTestTable />
        },
        {
          path: "scholarshipTestUser/:title/:id", element: (!d?.access?.includes('all') && !d?.access?.includes('151') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <SchlorshipTestUser />
        },
        {
          path: "addScholarshipTest", element: (!d?.access?.includes('all') && !d?.access?.includes('152')) ? <Navigate to="/404" replace /> : <AddScholarshipTest />

        },
        {
          path: "editScholarshipTest/:id", element: (!d?.access?.includes('all') && !d?.access?.includes('151') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditScholarshipTest />

        },
        
        {
          path: "dynamicLinks", element: (!d?.access?.includes('all') && !d?.access?.includes('221')) ? <Navigate to="/404" replace /> : <DeepLinkTable />
        },
        {
          path: "addDynamicLink", element: (!d?.access?.includes('all') && !d?.access?.includes('222')) ? <Navigate to="/404" replace /> : <AddDeepLink />

        },




        //Test Series
        {
          path: "test", element: (!d?.access?.includes('all') && !d?.access?.includes('31')) ? <Navigate to="/404" replace /> : <Test />

        },
        {
          path: "addTest", element: (!d?.access?.includes('all') && !d?.access?.includes('32')) ? <Navigate to="/404" replace /> : <AddTestSeries />

        },
//  Ebook
        { path: "ebookPurchases", element: (!d?.access.includes('all') && !d?.access?.includes('11114') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EbookPurchased /> },
        { path: "ebookPurchases/studentList/:ebookId", element: (!d?.access.includes('all') && !d?.access?.includes('11114') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EbookStudentList /> },

         
        {
          path: "ebooks", element: (!d?.access?.includes('all') && !d?.access?.includes('7111')) ? <Navigate to="/404" replace /> : <EbookTable />

        },
        {
          path: "addEbook", element: (!d?.access?.includes('all') && !d?.access?.includes('7112')) ? <Navigate to="/404" replace /> : <AddEbook />

        },
        {
          path: "addChapterToEbook/:ebookId/:title", element: (!d?.access?.includes('all') && !d?.access?.includes('7112')) ? <Navigate to="/404" replace /> : <AddChapter />

        },
        {
          path: "chapters/:ebookId/:title", element: (!d?.access?.includes('all') && !d?.access?.includes('7112')) ? <Navigate to="/404" replace /> : <ChapterTable />

        },
        {
          path: "topics/:chapterId/:title", element: (!d?.access?.includes('all') && !d?.access?.includes('7112')) ? <Navigate to="/404" replace /> : <TopicTable />

        },
        {
          path: "AddTopic/:chapterId/:title", element: (!d?.access?.includes('all') && !d?.access?.includes('7112')) ? <Navigate to="/404" replace /> : <AddTopic />
        },

        // quick learning 
        {
          path: "quicklearning/channels", element: (!d?.access?.includes('all') && !d?.access?.includes('8881') && !d?.access?.includes('8882')) ? <Navigate to="/404" replace /> : <Channel />

        },
        {
          path: "quicklearning/channel/:channelId", element: (!d?.access?.includes('all') && !d?.access?.includes('8883') && !d?.access?.includes('8884')) ? <Navigate to="/404" replace /> : <Short />

        },

        //Quiz

        {
          path: "quiz", element: (!d?.access?.includes('all') && !d?.access?.includes('41')) ? <Navigate to="/404" replace /> : <Quiz />

        },
        {
          path: "addQuiz", element: (!d?.access?.includes('all') && !d?.access?.includes('42')) ? <Navigate to="/404" replace /> : <AddQuiz />

        },
        {
          path: "uploadQuestionsForMultipleQuizes", element: (!d?.access?.includes('all') && !d?.access?.includes('42')) ? <Navigate to="/404" replace /> : <AddQuestionForQuizes />

        },
        {
          path: "addQuestionForMultipleQuizes", element: (!d?.access?.includes('all') && !d?.access?.includes('42')) ? <Navigate to="/404" replace /> : <AddQuestionForMultiQuizes />

        },
        {
          path: "addQuizForBatches", element: (!d?.access?.includes('all') && !d?.access?.includes('42')) ? <Navigate to="/404" replace /> : <AddQuizForBatches />

        },

        //Coupon
        {
          // path: "allCoupon", element: role !== 'Admin' && !d?.access?.includes('51') ? <Navigate to="/404" replace /> : <AllCoupon />
          path: "allCoupon", element: (!d?.access?.includes('all') && !d?.access?.includes('51')) ? <Navigate to="/404" replace /> : <AllCoupon1 />
        },
        {
          path: "addCoupon", element: (!d?.access?.includes('all') && !d?.access?.includes('52')) ? <Navigate to="/404" replace /> : <AddCoupon />

        },
        {
          path: "coupon/updateCouponDetails/:couponId/:from",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateCoupon />
        },



        //Resources
        {
          path: "recorded", element: (!d?.access?.includes('all') && !d?.access?.includes('61')) ? <Navigate to="/404" replace /> : <Recorded />

        },
        {
          path: "pathyakram", element: (!d?.access?.includes('all') && !d?.access?.includes('62')) ? <Navigate to="/404" replace /> : <Pathyakram />

        },
        {
          path: "notes", element: (!d?.access?.includes('all') && !d?.access?.includes('63')) ? <Navigate to="/404" replace /> : <Notes />

        },

        {
          path: "previousYearQuestionPapers", element: (!d?.access?.includes('all') && !d?.access?.includes('64')) ? <Navigate to="/404" replace /> : <PreviousYearQuestionPapers />

        },
        {
          path: "air", element: (!d?.access?.includes('all') && !d?.access?.includes('65')) ? <Navigate to="/404" replace /> : <AIR />

        },
        ,
        {
          path: "newsClip", element: (!d?.access?.includes('all') && !d?.access?.includes('66')) ? <Navigate to="/404" replace /> : <NewsClip />

        },
        {
          path: "teacherGenerated", element: (!d?.access?.includes('all') && !d?.access?.includes('67')) ? <Navigate to="/404" replace /> : <TeacherGenerated />

        },
        {
          path: "customNotification", element: (!d?.access?.includes('all') && !d?.access?.includes('68')) ? <Navigate to="/404" replace /> : <CustomNotification />

        },


        //Assignment
        {
          path: "assignment", element: (!d?.access?.includes('all') && !d?.access?.includes('71')) ? <Navigate to="/404" replace /> : <Assignment />

        },

        //Manage Staff
        {
          path: "staff", element: (!d?.access?.includes('all') && !d?.access?.includes('81')) ? <Navigate to="/404" replace /> : <Staff />

        },
        //Testimonial
        {
          path: "testimonial", element: (!d?.access?.includes('all') && !d?.access?.includes('91')) ? <Navigate to="/404" replace /> : <Testimonial />

        },
        {
         
          path: "resultBanner", element: (!d?.access?.includes('all') && !d?.access?.includes('9111')) ? <Navigate to="/404" replace /> : <ResultBanner />
        },
        {
          path: "resultStory", element: (!d?.access?.includes('all') && !d?.access?.includes('9112')) ? <Navigate to="/404" replace /> : <ResultStory />

        },


        //General Setting
        {
          path: "banner", element: (!d?.access?.includes('all') && !d?.access?.includes('101')) ? <Navigate to="/404" replace /> : <Banner />

        },
        {
          path: "ctaBanner", element: (!d?.access?.includes('all') && !d?.access?.includes('10001')) ? <Navigate to="/404" replace /> : <CTABanner />

        },
        {
          path: "logoutUsers", element: (!d?.access?.includes('all') && !d?.access?.includes('102')) ? <Navigate to="/404" replace /> : <LogoutUsers />

        },
        {
          path: "subject", element: (!d?.access?.includes('all') && !d?.access?.includes('103')) ? <Navigate to="/404" replace /> : <Subject />

        },

        {
          path: "youtube", element: (!d?.access?.includes('all') && !d?.access?.includes('104')) ? <Navigate to="/404" replace /> : <Youtube />

        },
        {
          path: "telegram", element: (!d?.access?.includes('all') && !d?.access?.includes('105')) ? <Navigate to="/404" replace /> : <Telegram />

        },
        ,
        {
          path: "category", element: (!d?.access?.includes('all') && !d?.access?.includes('106')) ? <Navigate to="/404" replace /> : <Category />

        },
        {
          path: "subCategory", element: (!d?.access?.includes('all') && !d?.access?.includes('107')) ? <Navigate to="/404" replace /> : <SubCategory />

        },
        {
          path: "updateSubCategory/:id", element: (!d?.access?.includes('all') && !d?.access?.includes('107')) ? <Navigate to="/404" replace /> : <UpdateSubCategory />

        },
        {
          path: "contactUs", element: (!d?.access?.includes('all') && !d?.access?.includes('108')) ? <Navigate to="/404" replace /> : <ContactUs />

        },
        // {
        //   path: "message", element: role !== 'Admin' && !d?.access?.includes('109') ? <Navigate to="/404" replace /> : <Messaging />

        // },
        {
          path: "message", element: <Messaging />

        },

        {
          path: "updateQuizQuestions/:questionId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateQuizQuestions />,
        },
        { path: "updateQuiz/:quizId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateQuiz /> },
        {
          path: "addQuizQuestion/:quizTitle/:quizId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddQuizQuestion />,
        },
        // { path: "quiz/:quizTitle/:quizId", element: <QuizQuestions /> },
        {
          path: "quiz/:quizTitle/:quizId/:studentId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <StudentResults />,
        },
        { path: "quiz/:quizTitle/:quizId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <QuizTable /> },
        // { path: "allCoupon", element: <AllCoupon /> },
        // { path: "addCoupon", element: <AddCoupon /> },
        // { path: "teacherGenerated", element: <TeacherGenerated /> },
        {
          path: "livestream/:channul/:lectId/:batchId/:desc",
          element: <LiveStream />,
        },


      ],
    },
    { path: "oauth/callback/", element: <Oath2callback /> },

    //{ path: "oauth2callback/", element: <Oath2callback /> },
    {
      path: "login",
      element: <Login />,
    },
    // {
    //   path: "flutter",
    //   element: <FlutterLiveStream />,
    // },
    // {
    //   path: 'file',
    //   element: <APP />,
    // },
    {
      path: "loginTeacher",
      element: <LoginTeacher />,
    },

    {
      path: "/",
      element: LoggedIn ? <Navigate to="/dashboard/app" replace /> : <Login />,
    },
    {
      path: "/404",
      element: <NotFound text='No Access' />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
