import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ToastContainer, toast } from "react-toastify";
import getAccess from "../../../utils/getAccess";
import { useTheme } from "@emotion/react";


const removeComma = (array) => {
  return array.reduce((acc, curr) => acc + " " + curr, "")
};



function CustomToolbar({ rows, columns, loading, setLoading, searchText, setSearchText, ...gridProps }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row, index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'serialNumber') {
            return index + 1;
          }
          else if (columnId === 'Stream') {
            return removeComma(row.Stream)
          }
          return row[columnId]?.toString().replaceAll(",", " ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'CourseOrder.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  );
};

export default function CourseOrderTable() {
  const d = getAccess();
  const theme = useTheme();
  const [rows, setRows] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rowCountState, setRowCountState] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25
  })
  const [loading, setLoading] = React.useState(true);
 
  const mapPageToNextCursor = React.useRef({});
  const queryOptions = React.useMemo(
    () => ({
      cursor: mapPageToNextCursor.current[paginationModel.page - 1],
      pageSize: paginationModel.pageSize,
    }),
    [paginationModel],
  );
  

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    let page = parseInt(queryOptions.cursor) + 1;
    let pageSize = parseInt(queryOptions?.pageSize)
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/courseOrders?page=${page}&pageSize=${pageSize}&searchText=${searchText}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson = await fullResponse.json();
      setRows(responseJson.data?.orders);
      setRowCountState(responseJson.data?.count);
      setLoading(false);
    }
    fetchUsers();
    

  }, [queryOptions]);

  const handlePaginationModelChange = (newPaginationModel) => {
    if (newPaginationModel.page === 0 || mapPageToNextCursor.current[newPaginationModel.page - 1]) {
      setPaginationModel(newPaginationModel);
    }
  };


  React.useEffect(() => {
    if (!loading) {
      mapPageToNextCursor.current[paginationModel.page] = paginationModel.page + 1;
    }
  }, [paginationModel.page, loading]);

  

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  

  const columns = [
    {
      field: "sno",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      // editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "orderId",
      headerName: "Order Id",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "batch",
      headerName: "Batch",
      type: "object",
      width: 350,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ?? "NA"}</div>
      ),
    },
    {
      field: "user",
      headerName: "User",
      type: "object",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "coupon",
      headerName: "Coupon",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ? params.value : "NA"}</div>
      ),
    },
    {
        field: "amount",
        headerName: "Amount",
        type: "object",
        width: 90,
        align: "left",
        headerAlign: "left",
      },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "purchaseDate",
      headerName: "Date",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    
    {
      field: "isPaid",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Paid" : "Un Paid"}
        </div>
      ),
    },
    
  ];



  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Stack direction='row' spacing={3} justifyContent='space-between' m={2} mr={10} sx={{ width: '90%', }}>
        <h2>Course Orders</h2> 
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        loading={loading}
        rowCount={rowCountState}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        pageSizeOptions={[25, 50, 100]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} loading={loading} setLoading={setLoading} searchText={searchText} setSearchText={setSearchText} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}